* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--Roboto);
}

:root {
    --primaryColor:#D2E7FF;
    --mainWhite: #fff;
    --offWhite: #F8F9FD;
    --mainText: #434343;
    --headingPrimary:#333333;
    --headingSecondary: #73A5E0;
    --darkGrey: #45505b;
    --boxShadow: 0px 4px 12px rgba(0, 0, 0, 0.09); 
    --bodyText: 14px;
    --headingSecondarySize: 14px;
    --headingPrimarySize: 32px;
    --primaryColorDark: #b7d9ff;
    --Roboto:'Roboto', sans-serif;
}
@keyframes expand {
    0% {
        transform: scale(0.8);
        opacity:0;
    }
    100% {
        transform: scale(1);
        opacity:1;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-50%);
    }

    100%{
        transform: translateY(0%);
    }
}

@keyframes fade {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
@keyframes popIn {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    20% {
        transform: scale(1.2);
    }
    30% {
        transform: scale(0.9);
    }
    45% {
        transform: scale(1.1);
    }
    57% {
        transform: scale(0.925);
    }
    70% {
        transform: scale(1.05);
    }
    78% {
        transform: scale(0.976);
    }
    87% {
        transform: scale(1.012); 
    }
    95% {
        transform: scale(0.997);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
body {
    background-color:var(--offWhite);
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    border:2px solid var(--headingSecondary);
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--mainWhite);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--primaryColor); 
    padding:10px;
    border:2px solid var(--offWhite);
    border-radius:5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColorDark); 
  }

h1 {
    font-weight:400;
    color: var(--headingPrimary);
    font-size: var(--headingPrimarySize);
}
p {
    margin-bottom: 10px;
}
#projects {
    background-color: var(--primaryColor);
}

#extracurricular {
    background-color: var(--offWhite);   
}
@keyframes gradientShift {
    0%{background-position:0% 65%}
    50%{background-position:100% 36%}
    100%{background-position:0% 65%}
}
.ctaBtn {
    padding:10px 15px;
    border-radius:5px;
    color: var(--headingPrimary);
    font-size:18px;
    transition:all 0.3s ease-in-out ;
    background-color:var(--primaryColor);
}
.ctaBtn:hover, .ctaBtn:active{
    animation: 5s ease 0s infinite gradientShift;
    background: linear-gradient(90deg, rgba(143,139,251,1) 0%, rgba(148,198,255,1) 25%, rgba(210,231,255,1) 50%, rgba(148,198,255,1) 75%, rgba(148,198,255,1) 75%, rgba(103,84,249,1) 100%);

    background-size: 400% 400%;
}

img#resumeImg{
    width: 80%;
    display:block;
    margin-top:15px;
}

section:first-of-type {
    padding-top:80px;
}
.section-wrap {
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    padding: 30px 0px;
}

.cards-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
}
.card-grid-elem {
    flex: 0 0 275px;
    margin: 20px;
}

.card-container { 
    box-shadow:var(--boxShadow);
    display:flex;
    flex-direction: column;
    animation: 0.3s ease-out 0s 1 expand;
    transition: all 0.3s ease-in-out;
}
#projects .card-container:hover {
    cursor:pointer;
    transform: translateY(-7px);
    box-shadow:0px 4px 12px rgba(255, 255, 255, 0.57);
}
.center {
    text-align:center;
}
.title {
    width:100%;
}
.card-link > * {
    margin: 5px;
}
.card-link{
    text-align: center;
    background-color: var(--primaryColor);
    margin: 10px;
    padding: 10px;
    color: var(--headingPrimary);
    flex-grow: 1;
    font-size: 14px;
    border-radius: 15px;
    display: flex;
    align-items:center;
    justify-content: center;
}
.card-footer .title h2{
    font-size: calc(var(--headingPrimarySize) - 10px);
    color: var(--headingSecondary);
    text-transform: capitalize;
    width:100%;
    font-weight:300;
    max-width:none;
}
.card-footer .title h3{
    font-size:var(--headingSecondarySize);
    color: var(--headingSecondary);
    text-transform: uppercase;
    width:100%;
    font-weight:400;
}
.card-footer {
    flex-grow:1;
    display:flex;
    flex-wrap: wrap;
    align-items:center;
    background-color: var(--mainWhite);
    padding:15px;
}
.card-heading{
    padding:15px;
    background-color: var(--darkGrey);
    height:64px;
}
.card-heading h2{
    color: var(--mainWhite) !important;
}
.card-footer h2 {
    max-width:calc(100% - 60px);
}
.card-container  h2{
    font-size: var(--headingSecondarySize);
    color: var(--headingSecondary);
    text-transform: uppercase;
    font-weight:400;
}

.card-body {
    font-size: var(--bodyText);
    padding:15px;
    flex-grow:2;
    line-height:calc(var(--bodyText) + 10px);
    background-color:var(--mainWhite);
    flex-grow:1;
}
.bg-transparent {
    background-color:transparent;
}
.align-left{
    margin-left: auto;
}
.section-title {
    display:flex;
    align-items: center;
    padding: 25px 0px;
    margin-left:-20px;
}
.section-title img {
    max-height:50px;
    margin-right:15px;
}
.card-container  img {
    max-width:100%;
    height:150px;
}

.modal-container {
    position:fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100vh;
    display:flex;
    justify-content:center;
    align-items:center;
}
.modal-close {
    display:none;
    text-align:right;
    background-color: #92bdf452;
    color: var(--headingPrimary);
    padding:5px 5px 0px 0px;
    font-size:20px;
    cursor:pointer;
}
.modal-background {
    position:absolute;
    background-color:var(--offWhite);
    width:100%;
    height:100vh;
    opacity:0.9;
    z-index:9;
}
.modal-body {
    min-height: 80vh;
    display:flex;
    justify-content: center;
    width:80%;
    max-width:1200px;
    z-index:9999;
}

.modal-container.show {
    display:flex;
    opacity:1;
}
.modal-container .card-container {
    animation: none;
    box-shadow:none;
}
.modal-body {
    animation: 0.4s cubic-bezier(0.09, 1.03, 0.71, 0.9) 0s 1 slideDown;
}
.modal-side{ 
    flex-grow:1;
    display:flex;
    justify-content:center;
    align-self:center;
}
.modal-side-wrap{
    background-color:white;
    display:flex;
    align-items:center;
    justify-content:center;
}
.img-carousel-container {
    width:60%;
    min-width: 60%;
    display:flex;
    flex-direction: column;
    flex-wrap:wrap;
    background-color:#92bdf452;
}
.img-carousel-container img {
    max-width: 100%;
    max-height: 60vh;
}
.modal-body .card-footer {
    flex-grow:0;
}
.active-img {
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    flex-grow:1;
}

.thumbnail-container {
    display: flex;    
    overflow-x: auto;
    padding:5px;
    width: 100%;
}
.thumbnail-container img{
    height:70px;
    width:auto;
    cursor:pointer;
    margin:5px;
}
.thumbnail-container img{
    opacity:0.5;
    transition: all 0.3s ease-in-out;
}

.thumbnail-container img.active {
    opacity:1;
}

a {
    color: var(--mainText);
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}
.navbar a{
    font-size:17px;
    background: linear-gradient(to right, var(--darkGrey), var(--darkGrey) 50%, var(--headingSecondary) 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
}

.navbar li:hover a{
    background-position: 0 100%;
}
img.logo{
    max-width:100%;
    height:auto;
}
.navbar {
    display:flex;
    flex-wrap:row; 
    background-color:white;
    opacity:0.8;
    width:100%;
    position:fixed;
    align-items:center;
    transition: all 0.3s ease-in-out;
}

.navbar:active, .navbar:hover {
    opacity:1;
}

.navbar h1 {
    padding:15px;
    font-size:var(--headingPrimarySize);
    color:var(--headingPrimary);
    letter-spacing:2px;
}

.links li{
    display:inline-block; 
    padding: 5px 20px;
}

.nav-icon {
    display:none;
    height:50px;
    padding:15px;
    font-size: calc(var(--headingPrimarySize) );
    left:0px;
    transition: all 0.3s ease-in-out;
    display:flex;
    align-items:center;
}

.nav-drawer {
    display:none;
    padding:20px;
    height:100vh;
    min-width:200px;
}

.nav-drawer li {
    display:block;
    padding:10px 0px;
    border-bottom: 2px solid #b7d8ff;
    position: relative;
}
.nav-drawer li:after{
    content:"";
    position:absolute;
    display:block;
    width:0%;
    bottom:-2px;
    border-bottom: 2px solid var(--darkGrey);
    transition: all 0.3s ease-in-out;
}
.nav-drawer li:hover:after, .nav-drawer li:active:after{
    width:100%;
}

.side-nav {
    position:absolute;
    right:0px;
    top:0px;
    height: 50px;
    display:none;
    flex-direction: column;
    z-index:999;
    width:55px;
    background-color:var(--mainWhite);
    transition: width 0.3s cubic-bezier(0.45, 2.21, 0.6, 0.02);
}

.side-nav.show{
    width:200px;
    height:100vh;
    border-left: 2px solid var(--primaryColor);
}

.show {
    display:block !important;

}

  /* new page */

  .pageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 100px;
    transition: all 1s ease-in-out;

  }

  .inputContainer h1 {
    text-transform: uppercase;
    transition: all 1s ease-in-out;
  }
  
  .inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 28px;
  }

  .inputContainer input {
    border: 2px solid #FFF;
    border-radius: 8px;
    padding: 16px;
    font-size: 38px;
    text-align: center;
    border-radius: 20px;
    outline: none;
    background: #FFF;
    box-shadow: 0px 12px 40px -8px rgba(64, 87, 109, 0.20), 0px 0px 0px 1px rgba(64, 87, 109, 0.04);
    transition: all 0.3s ease-in-out;
    width: 100%;
    position:relative;
  }

  .inputContainer input:focus {
    border:2px solid #434343;
    outline: none;
  }
  .inputContainer.active h1 {
    color: transparent;
  }
  .inputContainer .hidden {
    opacity: 0;
  }
  .inputContainer.active .hidden {
    color: #89D1E1;
    opacity: 0.5;
    transition: all 2s ease-in-out;
  }
  .pageContainer.active {
    background: #D8F6FF;
    /* Other CSS properties */
  }

  .confettiContainer {
    display: flex;
    padding: 68px;
    align-items: center;
    height: 100vh;
    justify-content: space-between;
  }

  .confettiContainer img {
    animation: popIn 1s ease-out;
    max-height: 80vh;
    max-width: 50%;
  }

  .twoColContainer {
    display: flex;
    flex-direction: row;
  }


@media screen and (max-width: 565px) {
    :root {
        --headingPrimarySize: 24px;
    }
    .side-nav{
        display:flex;
    }

    .links{
      display: none;
    }
  }


  @media screen and (max-width: 935px) {
    .modal-body{
        flex-direction: column;
    }
    .modal-close {
        display:block;
    }
    .img-carousel-container {
        width:100%;
        flex-grow:0;
        justify-content:center;
    }
    .thumbnail-container img {
        height:50px;
    }
    .thumbnail-container {
        padding:0px;
    }
    .active-img {
        display: flex;
        justify-content: center;
    }
    .active-img img {
        max-height:30vh;
        max-width:100%;
    }
    .modal-container .card-body {
        min-height:30vh;
        overflow-y: scroll;
        max-height:40vh;
    }
    .modal-side-wrap {
        flex-grow:1;
    }
    .modal-container .card-container {
        flex-grow: 1;
    }
    .modal-body .card-footer {
        flex-grow:0;
    }
    .card-footer{
        padding:5px;
    }
    .card-link {
        margin:5px;
        padding:3px;
    }
    .confettiContainer {
        flex-direction: column;
        justify-content: start;
        height: auto;
    }
    .confettiContainer img {
        max-width: 100%;
        max-height: none;
        padding: 24px;
    }
    .twoColContainer {
        flex-direction: column;
    }
  }

@keyframes bounce {
    0%   { 
        transform: translateY(0) scale(1); 
    }
    50%  { 
        transform: translateY(-40px) scale(0.6); 
    }

    100% { 
        transform: translateY(0) scale(1); 
    }
}
  .loading li{
    display:inline-block;
    height:20px;
    width:20px;
    background-color:var(--primaryColor);
    margin:10px;
    margin-top:40px;
    border-radius:50%;
  }
  .loading li:nth-child(1){
    animation: 1.5s cubic-bezier(0.08, 0.1, 0.06, 0.82) 0.8s infinite bounce;
  }
  .loading li:nth-child(2) {
    animation: 1.5s cubic-bezier(0.08, 0.1, 0.06, 0.82) 1s infinite bounce;
  }
  .loading li:nth-child(3) {
    animation: 1.5s cubic-bezier(0.08, 0.1, 0.06, 0.82) 1.2s infinite bounce;

  }
