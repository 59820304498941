.carousel-container {
    border: 3px dashed;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
}
.carousel-images-container {
    position: relative;
    width: 600px;
    height: 400px;
    background-color:#f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 48px;
}

.carousel-indicator {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    transition:all 0.3s ease-in-out ;
}

.carousel-indicator-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    opacity: 0.6;
    cursor: pointer;
    transition:all 0.3s ease-in-out ;
}

.carousel-indicator-dot.active {
    opacity: 1;
    transform: scale(1.3);
}

.controls-container {
    display: flex;
    flex-direction: row;
    padding-top: 12px;
    gap: 8px;
    justify-content: flex-end;
    padding: 24px;
}


.carousel-button {
    border-radius: 50%;
    outline: none;
    border: none;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:all 0.3s ease-in-out ;
    border: 2px solid transparent;
}

.carousel-button:hover {
    border:2px dashed white;
    background-color: white;
}

@media screen and (max-width: 935px) {
    .carousel-images-container {
        width: 100%;
    }
}
