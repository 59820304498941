.giftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 500px;
    height: auto;
}

.presentImagesContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 38px;
    z-index: 99999;
}

.presentImagesContainer img {
    max-width: 300px;
}

@keyframes shake {
    0% { transform: translate(2px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(2px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(2px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(2px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
  
  #presentTop:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
    cursor: pointer;
  }

  .presentLabel {
    padding: 8px;
  }

  .fade-out {
    animation: fadeOut 1s ease-out forwards;
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }

  .fade-in {
    animation: fadeIn 1s ease-out forwards;
    height: auto;
  }
  .revealedItem.fade-in {
    height: auto;
  }

.revealedItem {
    opacity: 0;
    height: 200px;
}
.revealedItem img{
    max-width: 100%;
}
